import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import integrationsImage from '../../../static/images/integrations.svg'
import ratingsImage from '../../../static/images/integration/ratings.svg'
import thankyouImage from '../../../static/images/integration/thank_you_page.svg'

function Integrations({ integrations }) {
  return (
    <React.Fragment>
      {integrations.map(integration => (
        <a
          className={'integration-wrap'}
          key={integration.name}
          href={integration.url}
          target="_blank"
        >
          <div className={'integration'}>
            <img
              src={integration.logo}
              alt={integration.name}
              className={'integration__logo'}
            />
            <h3>{integration.name}</h3>
            <span className={'integration__category'}>
              {integration.category}
            </span>
          </div>
        </a>
      ))}
    </React.Fragment>
  )
}

const PartnersPage = () => {
  const {
    site: { siteMetadata },
    allIntegrationsJson
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            partnerTypeformUrl
          }
        }
        allIntegrationsJson {
          edges {
            node {
              name
              blogUrl
              category
              url
              logo
            }
          }
        }
      }
    `
  )

  const integrations = allIntegrationsJson.edges.map(({ node }) => node)
  const productReviewIntegrations = integrations.filter(
    ({ category }) => category === 'Product Reviews'
  )
  const thankYouPageIntegrations = integrations.filter(
    ({ category }) => category === 'Thank you page'
  )

  return (
    <Layout>
      <SEO title="Integrations" />
      <div className={'page-header partners'}>
        <h1>
          Connect Personalized Recommendations
          <br /> to your favorite tools
        </h1>
        <p>
          Personalized Recommendations integrates with popular tools and apps
          you already use
          <br /> to maximize the impact of recommendations and accelerate sales.
        </p>
        <img alt={'Integrations'} src={integrationsImage} />
      </div>
      <div className={'call-to-action'}>
        <div className={'container integrations-section'}>
          <div className={'integrations-section__hero'}>
            <h1>
              Create Social Proof by displaying product ratings from apps
              you already use.
            </h1>
            <img className={'hero-section__image'} src={ratingsImage} />
          </div>
          <div className={'integrations-section__list'}>
            <Integrations integrations={productReviewIntegrations} />
          </div>
        </div>

        <div className="container integrations-section integrations-section--flipped">
          <div className={'integrations-section__hero'}>
            <img className={'hero-section__image'} src={thankyouImage} />
            <h1>Customize the Thank You Page of your store.</h1>
          </div>
          <div className={'integrations-section__list'}>
            <Integrations integrations={thankYouPageIntegrations} />
          </div>
        </div>
      </div>
      <div className={'integrations-box__request'}>
        <p>
          An integration with your favorite tool is missing? <br />
          <strong>Let us know!</strong>
        </p>
        <a
          href="mailto:support@loopclub.io?subject=Integration request"
          className={'integrations-box__request-link'}
        >
          Request Integration
        </a>
      </div>
    </Layout>
  )
}

export default PartnersPage
